/**
 * Ensure helper to do some null check 
 */
class Ensure {

    /**
     * This function is used to check
     * Is element is not null
     * @param {DOM} element target
     * @returns boolean
     */
    ElementIsNotNull(element) {
        if (element != null) return true
        return false
    }
}

module.exports = Ensure

const Animate = require('./animate')
const Delay = require('./delay')

class Sidebar {

    Controll(target, links) {
        this.Animate = new Animate()
        this.Delay = new Delay()

        if (this.isSidebarIsOpened(target)) {
            console.log('sidebar is opened try to close')
            this.closeSidebar(target, links)
        } else {
            console.log('sidebar is closed try to open')
            this.openSidebar(target, links)
        }
    }

    closeSidebar(target, links) {
        this.Animate.HideItems(links)
        target.classList.remove('active')
    }

    openSidebar(target, links) {
        console.log('openSidebar')

        // open sidebar
        target.classList.add('active')

        // animate link sidebar
        this.Animate.ShowItems(links)
    }

    isSidebarIsOpened(target) {
        if (target.classList.contains('active')) return true
        return false;
    }

}

module.exports = Sidebar

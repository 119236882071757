/**
 * Navbar helper to controll navbar
 */
const Sidebar = require('./sidebar')

class NavbarHelper {

    /**
     * This function is used to controll navbar
     * @param {DOM} target 
     * @returns 
     */
    ControllNavbar = (sidebar, navlinks, button) => {

        this.Sidebar = new Sidebar()

        if(this.isMenuIcon(button)) {
            console.log('is menu icon')
            button.innerHTML = 'close'
        }
        else {
            console.log('is menu icon false')
            button.innerHTML = 'menu'
        }
        this.Sidebar.Controll(sidebar, navlinks)
       
    }

    isMenuIcon(target) {
        if(document.querySelector("#menu").innerHTML.includes('menu')) return true
        return false
    }

}

module.exports = NavbarHelper

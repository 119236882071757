/**
 * Helper class to navigate specific URL
 */
class Navigate {

    /**
     * 
     * @param {string} targetURL 
     * @param {boolean} isOpenNewWindow 
     * @returns window.location
     */
    toURL(targetURL, isOpenNewWindow) {
        if(isOpenNewWindow) return window.open(targetURL)
        return window.location.href = targetURL
    }
}

module.exports = Navigate
// Bootstrap
require('bootstrap')
var $ = require("jquery");

// Owl carousel
require('../../node_modules/jquery/dist/jquery.js')
// require('../../node_modules/owl.carousel/dist/owl.carousel.min.js')
// scroll reveal
const ScrollReveal = require('scrollreveal').default

// import require component
var Ensure = require('./helper/ensure')
var NavbarHelper = require('./helper/navbar')
var Navigate = require('./helper/navigate')


// Google analystic
const {
    gtag,
    install
} = require("ga-gtag");


// Declare variable

const MobileMenuButton = document.querySelector("#mobile-menu-button")
const SidebarElement = document.querySelector("#sidebar")
const BtnContact = document.querySelector("#btn-contact")
const BtnGallery = document.querySelector("#btn-gallery")
const BtnCarouselNext = document.querySelector("#carousel-next")
const BtnCarouselPrev = document.querySelector("#carousel-prev")


// Whatsapp api
const WhatsappAPI = "https://wa.me/"
const BanaBahaPhone = "628112864488"

// initialize
initApp()

/**
 * Create function to init All app requirement
 */
function initApp() {
    Ensure = new Ensure()
    NavbarHelper = new NavbarHelper()
    Navigate = new Navigate()
    initClickListener()

    animateScrollReveal()

    initGoogleAnalystic()

}

function animateScrollReveal() {
    // Reveal when scroll
    window.sr = ScrollReveal()
    window.sr.reveal('.brand')
    window.sr.reveal('.carousel-text')
    window.sr.reveal('.col')

}

/**
 * Create function init click listener
 */
function initClickListener() {

    // Navbar event listener
    if (Ensure.ElementIsNotNull(MobileMenuButton)) {
        MobileMenuButton.addEventListener('click', (e) => {
            e.preventDefault()
            var NavLinks = document.querySelectorAll(".nav-item-sidebar")
            var BtnMenuIcon = document.querySelector("#menu")
            NavbarHelper.ControllNavbar(SidebarElement, NavLinks, BtnMenuIcon)
        })
    }

    // Button contact listener
    if (Ensure.ElementIsNotNull(BtnContact)) {
        BtnContact.addEventListener('click', (e) => {
            e.preventDefault()
            Navigate.toURL(`${WhatsappAPI}/${BanaBahaPhone}`, true)
        })
    }

    // Button gallery listener
    if (Ensure.ElementIsNotNull(BtnGallery)) {
        BtnGallery.addEventListener('click', (e) => {
            e.preventDefault()
            Navigate.toURL('#galeri', false)
        })
    }
}

// analystic
function initGoogleAnalystic() {


    install('G-0J9479YGFT');
    gtag('config', 'G-0J9479YGFT');
}


$(".nav-item").each((e) => {
    $(this).addClass('nav-active')
})


let navLinks = document.querySelectorAll(".nav-item")
let navLinksSidebar = document.querySelectorAll(".nav-item-sidebar")
const currentUrl = window.location.href
navLinks.forEach((item, index) => {
    const url = item.querySelector('a').href
    if (url.split('/').length >= 4) {
        let path = url.split("/")[3]
        if (currentUrl.includes(path)) {
            item.classList.add('nav-active')
        }
    }
})

navLinksSidebar.forEach((item, index) => {
    console.log(item)
    const url = item.querySelector('a').href
    if (url.split('/').length >= 4) {
        let path = url.split("/")[3]
        if (currentUrl.includes(path)) {
            console.log(item)
            item.classList.add('nav-active')
        }
    }
})
// document.querySelectorAll('.nav-item')[0].
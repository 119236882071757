/**
 * Create Animate class to easy animate element
 */
class Animate {

    linksAnimateDelay = 110 // Delay in ms

    /**
     * Controll link navigation to show
     * @param {DOM} links 
     */
    ShowItems(links) {

        links.forEach((link, index) => {
            setTimeout(() => {
                link.classList.remove("d-none")
            }, index * this.linksAnimateDelay);
        });
    }

    /**
     * Controll navigation close
     * @param {DOM} links 
     */
    HideItems(links) {

        for (let index = links.length - 1; index >= 0; index--) {
            setTimeout(() => {
                links[index].classList.add("d-none")
            }, index * this.linksAnimateDelay);
        }
    }



    /**
     * Check wether element is visible
     * @param {DOM} target 
     * @returns boolean
     */
    isElementVisible(target) {
        if (target.classList.contains('d-none')) return false
        return true
    }

    


}

module.exports = Animate

/**
 * Create Dealy class helper
 */
class Delay {

    /**
     * Create Task with delay
     * @param {Callback} action 
     * @param {Number} timeout 
     */
    Task(action, timeout) {
        setTimeout(() => {
            action()
        }, timeout);
    }
}

module.exports = Delay
